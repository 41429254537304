<template lang="pug">
  v-container#entryGuideModal(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Registro de Guías
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-form(ref='formEntryGuide' v-model='validEntryGuide' lazy-validation='' autocomplete="random-string")
          v-container.py-0
            v-row(style="margin-top: 10px;")
              v-col.py-0(cols="12" md="6")
                v-autocomplete(dense="" :rules='[rules.required]' v-model='entryGuide.providerSelect' :items='providersLst' return-object='return-object' item-text='completeData' label='Proveedor Interno')
              v-col.py-0(cols="12" md="6")
                v-autocomplete(dense="" :rules='[rules.required]' v-model='entryGuide.providerSelect' :items='providersLstExterno' return-object='return-object' item-text='completeData' label='Proveedor Externo')
              v-col(cols="12" md="3" style="margin-top: 10px;")
                v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='entryGuide.dateEntry' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on }')
                    v-text-field(dense readonly v-model='entryGuide.dateEntry' label='Fecha de Ingreso' prepend-icon='mdi-calendar' v-on='on')
                  v-date-picker(@change='$refs.menuFrom.save(entryGuide.dateEntry)' v-model='entryGuide.dateEntry' no-title='' scrollable='' style='margin:0px;' color="green lighten-1")
              v-col(cols='12' md='3')
                v-text-field(style="margin-top: 15px;" dense v-model='entryGuide.codeGuide' :rules="requeridos" label='Nro. Verificado' @keypress="isNumber($event)" type='text' maxlength="17")
              v-col(cols='12' md='3')
                v-text-field(@keyup="entryGuide.referralGuide = entryGuide.referralGuide.toString().toUpperCase()" style="margin-top: 15px;" dense v-model='entryGuide.referralGuide' :rules="requeridos" label='Guía de Remisión' type='text' maxlength="100")
              v-col(cols='12' md='3')
                v-text-field(@keyup="entryGuide.codeProduct = entryGuide.codeProduct.toString().toUpperCase()" style="margin-top: 15px;" dense v-model='entryGuide.codeProduct' :rules="requeridos" label='Código' type='text' maxlength="25")
              v-col(cols='12' md='4')
                v-autocomplete(@keyup="entryGuide.item = entryGuide.item.toString().toUpperCase()" :rules='[rules.required]' v-model='entryGuide.item' :items='productsLst' item-text='name' label='Productos - Item')
                // v-text-field(@keyup="entryGuide.item = entryGuide.item.toString().toUpperCase()" style="margin-top: 15px;" dense v-model='entryGuide.item' :rules="requeridos" label='Item' type='text' maxlength="150")
              v-col(cols='12' md='2')
                v-text-field(@keyup="calculateTotal" style="margin-top: 15px;" dense v-model='entryGuide.weight' :rules="requeridos" label='Peso Verificado' @keypress="isNumber($event)" type='text' maxlength="150")
              v-col(cols='12' md='2')
                v-text-field(@keyup="calculateDiscounts" style="margin-top: 15px;" dense v-model='entryGuide.inputweight' :rules="requeridos" label='Peso de Entrada' @keypress="isNumber($event)" type='text' maxlength="150")
              v-col(cols='12' md='2')
                v-text-field(@keyup="calculateTotal" style="margin-top: 15px;" dense v-model='entryGuide.kiloprice' :rules="requeridos" label='Precio Kilo' @keypress="isNumber($event)" type='text' maxlength="150")
              v-col(cols='12' md='2')
                v-text-field(style="margin-top: 15px;" dense v-model='entryGuide.discounts' :rules="requeridos" label='Descuentos' @keypress="isNumber($event)" type='text' maxlength="150")
              v-col(cols='12' md='7')
                v-text-field(@keyup="entryGuide.store = entryGuide.store.toString().toUpperCase()" style="margin-top: 15px;" dense v-model='entryGuide.store' :rules="requeridos" label='Bodega' type='text')
              v-col(cols='12' md='3')
                v-text-field(@keyup="entryGuide.plate = entryGuide.plate.toString().toUpperCase()" style="margin-top: 15px;" dense v-model='entryGuide.plate' :rules="requeridos" label='Placa' type='text' maxlength="8")
              v-col(cols='12' md='2')
                v-text-field(readonly style="margin-top: 15px;" dense v-model='entryGuide.total' :rules="requeridos" label='Total' type='text' maxlength="150")
              v-col(cols='12' md='12')
                v-text-field(@keyup="entryGuide.provider = entryGuide.provider.toString().toUpperCase()" style="margin-top: 15px;" dense v-model='entryGuide.provider' :rules="requeridos" label='Proveedor' type='text')
              v-col(cols="12" md="4" offset-md="4")
                v-btn.mr-0(color='primary' @click='saveEntryGuide' style="width: 100%;" :loading="loadingSave")
                  | GUARDAR GUÍA
</template>
<script>
  import moment from 'moment'
  var accounting = require('accounting')
  export default {
    props: {
      entryGuideToEdit: {
        default: function () {
          return {
            codeGuide: '',
            referralGuide: '',
            codeProduct: '',
            dateEntry: moment(new Date()).format('YYYY-MM-DD'),
            item: '',
            weight: '',
            inputweight: '',
            kiloprice: '',
            discounts: '',
            total: '',
            plate: '',
            provider: '',
            state: 'ACTIVO',
            store: '',
            providerSelect: null,
          }
        },
        type: Object,
      },
    },
    data: data => ({
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      loadingSave: false,
      requeridos: [
        value => !!value || 'Requerido',
      ],
      menuFrom: false,
      validEntryGuide: true,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      entryGuide: {
        codeGuide: '',
        referralGuide: '',
        codeProduct: '',
        dateEntry: moment(new Date()).format('YYYY-MM-DD'),
        item: '',
        weight: '',
        inputweight: '',
        kiloprice: '',
        discounts: '',
        total: '',
        plate: '',
        provider: '',
        state: 'ACTIVO',
        store: '',
        providerSelect: null,
      },
      productData: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '1',
        activeValue: '0',
        activeWValue: '0',
        activeValueWholesaler: '0',
        activeWValueWholesaler: '0',
        purchaseValue: '0',
        purchaseWValue: '0',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
        inecCode: null,
      },
    }),
    computed: {
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
      providersLstExterno () {
        return this.$store.state.provider.lstProvidersExternos
      },
      productsLst () {
        return this.$store.state.product.lstProducts
      },
    },
    watch: {
      entryGuideToEdit () {
        this.entryGuide = this.entryGuideToEdit
      },
    },
    methods: {
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      calculateTotal () {
        this.entryGuide.total = (parseFloat(this.entryGuide.weight) * parseFloat(this.entryGuide.kiloprice)).toFixed(2)
        this.calculateDiscounts()
      },
      calculateDiscounts () {
        if (this.entryGuide.weight && this.entryGuide.inputweight) {
          if (this.entryGuide.weight !== '' && this.entryGuide.inputweight !== '') {
            this.entryGuide.discounts = accounting.toFixed(parseFloat(this.entryGuide.inputweight) - parseFloat(this.entryGuide.weight), 2)
          }
        }
        this.snackbar = {
          show: true,
          color: 'green',
          text: 'Cálculo realizado correctamente',
        }
      },
      saveEntryGuide () {
        if (this.$refs.formEntryGuide.validate()) {
          this.loadingSave = true
          this.productData.codArticle = this.entryGuide.codeProduct
          this.productData.barcode = this.entryGuide.codeProduct
          this.productData.name = this.entryGuide.item
          this.productData.description = this.entryGuide.item
          this.productData.completeData = this.productData.codArticle + ' | ' + this.productData.name + ' | ' + this.productData.description + ' | ' + this.productData.barcode
          this.$store.dispatch('entryguides/saveEntryGuide', [this.entryGuide, this.productData, true]).then(res => {
            this.loadingSave = false
            if (res) {
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Guía agregada de manera correcta.',
              }
              this.$emit('closeModal')
            } else {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de guardar la guía de remisión.',
              }
            }
          }, reject => {
            this.loadingSave = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de guardar la guía de remisión.',
            }
          })
        }
      },
    },
    mounted () {
      this.entryGuide = this.entryGuideToEdit
    },
  }
</script>
